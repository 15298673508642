"use client";
import Image from "next/image";
import React, { useState } from "react";
import { MdMenu } from "react-icons/md";
import MobileMenu from "./MobileMenu";
export default function MobileNavigation() {
  const [showMenu, setShowMenu] = useState(false);
  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };
  return (
    <>
      <div className="mobile-navigation">
        <div className="logo-wrapper">
          <Image src="/logo.png" alt="Kodiak Logo" width={1920} height={1080} />
        </div>
        <div>
          <MdMenu onClick={() => setShowMenu(!showMenu)} />
        </div>
      </div>
      <div
        className={
          showMenu ? `mobile-menu-wrapper open` : `mobile-menu-wrapper`
        }
      >
        <MobileMenu close={handleShowMenu} />
      </div>
    </>
  );
}
