import Link from "next/link";
import React from "react";
import { MdArrowForwardIos } from "react-icons/md";
import Image from "next/image";
import { IoCloseSharp } from "react-icons/io5";

export default function MobileMenu(props) {
  return (
    <div className="mobile-menu">
      <div className="mobile-menu-header">
        <Link href="/">
          <Image
            src="/logo.png"
            alt="Timberline Logo"
            height={1080}
            width={1920}
          />
        </Link>
        <IoCloseSharp onClick={() => props.close()} />
      </div>
      <ul>
        <li>
          <Link href="/" onClick={() => props.close()}>
            Home
          </Link>
          <MdArrowForwardIos />
        </li>
        <li>
          <Link href="/models" onClick={() => props.close()}>
            Products
          </Link>
          <MdArrowForwardIos />
        </li>
        <li>
          <Link href="/videos" onClick={() => props.close()}>
            Videos
          </Link>
          <MdArrowForwardIos />
        </li>
        <li>
          <Link href="/gallery" onClick={() => props.close()}>
            Gallery
          </Link>
          <MdArrowForwardIos />
        </li>
        <li>
          <Link href="/about" onClick={() => props.close()}>
            About
          </Link>
          <MdArrowForwardIos />
        </li>
        <li>
          <Link href="/support" onClick={() => props.close()}>
            Support
          </Link>
          <MdArrowForwardIos />
        </li>
        <li>
          <Link href="/contact" onClick={() => props.close()}>
            Contact Us
          </Link>
          <MdArrowForwardIos />
        </li>
        <li style={{ borderBottom: "none" }}>
          <Link
            className="request-a-demo"
            href="/demo"
            onClick={() => props.close()}
          >
            Request a Demo
          </Link>
        </li>

        <li>
          <Link
            className="login"
            href="https://www.portal.rpscorporation.com/"
            target="_blank"
            onClick={() => props.close()}
          >
            Login
          </Link>
        </li>
      </ul>
    </div>
  );
}
