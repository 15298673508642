"use client";
import Image from "next/image";
import React, { useState, useEffect, useRef } from "react";
import DesktopDropDown from "./DesktopDropDown";
import Link from "next/link";
import { useRouter } from "next/navigation";

export default function DesktopNavigation(props) {
  const { machines } = props;
  const router = useRouter();
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        showMenu &&
        event.target.id !== "menu-handler" &&
        menuRef.current &&
        !menuRef.current.contains(event.target)
      ) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [showMenu]); // Updated dependency array

  const handleShowMenu = () => {
    setShowMenu(!showMenu);
  };

  return (
    <>
      <div className="desktop-navigation">
        <Image
          className="navigation-logo"
          src="/logo.png"
          alt="Timberline Logo"
          width={1920}
          height={1080}
          onClick={() => {
            router.push("/");
            setShowMenu(false);
          }}
          style={{ cursor: "pointer" }}
        />
        <div className="navigation-links">
          {/* Keeping onClick in Link as per your request */}
          <Link href="/" onClick={() => setShowMenu(false)}>
            Home
          </Link>
          <button id="menu-handler" onClick={handleShowMenu}>
            Floors Scrubbers
          </button>
          <Link href="/videos" onClick={() => setShowMenu(false)}>
            Videos
          </Link>
          <Link href="/gallery" onClick={() => setShowMenu(false)}>
            Images
          </Link>
          <Link href="/support" onClick={() => setShowMenu(false)}>
            Support
          </Link>
          <Link href="/about" onClick={() => setShowMenu(false)}>
            About
          </Link>
          <Link href="/contact" onClick={() => setShowMenu(false)}>
            Contact Us
          </Link>
          <Link
            href="https://www.portal.rpscorporation.com/"
            onClick={() => setShowMenu(false)}
            target="_blank"
          >
            Login
          </Link>

          <Link href="tel:1877-401-0730" onClick={() => setShowMenu(false)}>
            1-877-401-0730
          </Link>
        </div>
      </div>
      <div
        ref={menuRef}
        className={`desktop-navigation-menu ${showMenu ? "open" : ""}`}
      >
        <DesktopDropDown
          machines={machines}
          closeMenu={() => setShowMenu(false)}
        />
      </div>
    </>
  );
}
