"use client";

import Image from "next/image";
import React, { useState } from "react";
import Link from "next/link";
import { useRouter } from "next/navigation";

export default function DesktopDropDown(props) {
  const machines = props.machines;
  const [selectedMachine, setSelectedMachine] = useState(machines[0]);
  const [selectedMachineImage, setSelectedMachineImage] = useState(
    machines[0].attributes.main_image.data.attributes.url
  );
  const [selectedMachineName, setSelectedMachineName] = useState(
    machines[0].attributes.name
  );
  const [selectedDeck, setSelectedDeck] = useState(null);
  const handleSelectedMachineSeries = (machine) => {
    setSelectedMachine(machine);
    setSelectedMachineImage(
      machine.attributes.main_image.data.attributes.url || null
    );
    setSelectedMachineName(machine.attributes.name);
  };
  const handleSelectedDeck = (deck) => {
    if (selectedMachine === "Floor Machines") {
      return;
    }
    setSelectedMachineName(deck.name);
    setSelectedMachineImage(deck.image.data.attributes.url);
    setSelectedDeck(deck);
  };

  return (
    <div className="desktop-dropdown-wrapper">
      <div className="col-1">
        <h1>Floor Scrubbers</h1>
        <h3>Choose Your Series</h3>
        <ul>
          {machines.map((item) => (
            <li key={item.id}>
              <button onClick={() => handleSelectedMachineSeries(item)}>
                {item.attributes.name}
                <span>
                  {item.attributes.spec[0].spec_label}{" "}
                  {item.attributes.spec[0]?.spec_description}
                </span>
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="col-2">
        <h1>{selectedMachine?.attributes.style.data.attributes.style}</h1>
        <h3>Choose Your Size</h3>
        <ul>
          {selectedMachine?.attributes?.decks.map((deck) => (
            <li key={deck.id} onMouseEnter={() => handleSelectedDeck(deck)}>
              <Link
                onClick={() => {
                  props.closeMenu();
                }}
                href={{
                  pathname: `/models/${selectedMachine.attributes.slug}`,
                  query: {
                    deckType: selectedDeck?.type.data.attributes.type,
                    deckSize: selectedDeck?.size,
                  },
                }}
                as={`/models/${selectedMachine.attributes.slug}?deckType=${selectedDeck?.type.data.attributes.type}&deckSize=${selectedDeck?.size}`}
              >
                <button>
                  <span className="deck-name">
                    {deck.type.data.attributes.type === "None" ||
                    deck.type.data.attributes.type === "Sweeper" ? (
                      <>{deck.name}</>
                    ) : (
                      <>
                        {deck.name}{" "}
                        {deck.type.data.attributes.type.replace(" - 3b", "")}
                      </>
                    )}
                  </span>
                  <span>
                    {deck.size} {deck.label}
                  </span>
                </button>
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {selectedMachine ? (
        selectedMachine?.attributes.isFloorMachine ? (
          <Link
            href={{
              pathname: `/models/${selectedMachine.attributes.slug}`,
            }}
          >
            <div
              className="selected-machine-section"
              onClick={() => {
                props.closeMenu();
              }}
            >
              <div className="left-section">
                <h2>{selectedMachineName}</h2>
                <div className="selected-machine-variants">
                  <div className="selected-machine-series">
                    <div className="selected-machine-image">
                      <Image
                        src={selectedMachineImage}
                        alt=""
                        height={1080}
                        width={1920}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-section">
                {selectedMachine.attributes.isFloorMachine ? (
                  <div className="selected-machine-specs">
                    <h4>Specifications</h4>
                    <ul>
                      {selectedMachine.attributes.spec.map((spec) => (
                        <li key={spec.id}>
                          <span className="label">{spec.spec_label}</span>
                          <span className="description">
                            {spec.spec_description}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                ) : (
                  <div className="selected-machine-specs">
                    <h4>Specifications</h4>
                    <ul>
                      {selectedMachine.attributes.spec.map((spec) => (
                        <li key={spec.id}>
                          <span className="label">{spec.spec_label}</span>
                          <span className="description">
                            {spec.spec_description}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="deck-type-image">
                  {/* {selectedMachine.type.data?.attributes.image.data.attributes
                    .url && (
                    <Image
                      src={
                        selectedMachineSeriesDeckTypes.type.data.attributes
                          .image.data.attributes.url
                      }
                      alt=""
                      height={1080}
                      width={1920}
                    />
                  )} */}
                  <div className="deck-type-bullet">
                    <h5>
                      {/* {selectedMachine.type.data?.attributes.type && (
                        <span>
                          Features
                          {
                            selectedMachineSeriesDeckTypes.type.data.attributes
                              .type
                          }
                        </span>
                      )} */}
                    </h5>
                    <ul>
                      {/* {selectedMachine.type.data?.attributes.bullet.map(
                        (bullet) => (
                          <li key={bullet.id}>{bullet.bullet}</li>
                        )
                      )} */}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        ) : (
          <Link
            href={{
              query: {
                deckType: selectedDeck?.type.data.attributes.type,
                deckSize: selectedDeck?.size,
              },
              pathname: `/models/${selectedMachine.attributes.slug}`,
            }}
          >
            <div
              className="selected-machine-section"
              onClick={() => {
                props.closeMenu();
              }}
            >
              <div className="left-section">
                <h2>{selectedMachineName}</h2>
                <div className="selected-machine-variants">
                  <div className="selected-machine-series">
                    <div className="selected-machine-image">
                      <Image
                        src={selectedMachineImage}
                        alt=""
                        height={1080}
                        width={1920}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="right-section">
                {selectedMachine.attributes.spec?.length > 0 && (
                  <div className="selected-machine-specs">
                    <h4>Specifications</h4>
                    <ul>
                      {selectedMachine.attributes.spec.map((spec) => (
                        <li key={spec.id}>
                          <span className="label">{spec.spec_label}</span>
                          <span className="description">
                            {spec.spec_description}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                )}
                <div className="deck-type-image">
                  {selectedDeck?.type.data?.attributes.image.data?.attributes
                    .url && (
                    <Image
                      src={
                        selectedDeck?.type.data.attributes.image.data.attributes
                          .url
                      }
                      alt=""
                      height={1080}
                      width={1920}
                    />
                  )}
                  <div className="deck-type-bullet">
                    <h5>
                      {selectedDeck?.type.data?.attributes.type && (
                        <span>
                          {selectedDeck?.type.data.attributes.type.replace(
                            " - 3b",
                            ""
                          )}
                        </span>
                      )}
                    </h5>
                    <ul>
                      {selectedDeck?.type.data?.attributes.bullet.map(
                        (bullet) => (
                          <li key={bullet.id}>{bullet.bullet}</li>
                        )
                      )}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </Link>
        )
      ) : (
        <div
          className="col-3"
          style={{
            backgroundImage: `url(/assets/timberline-menu-bg.png)`,
          }}
        ></div>
      )}
    </div>
  );
}
