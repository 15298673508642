"use client";
import React from "react";
import TawkMessengerReact from "@tawk.to/tawk-messenger-react";

export default function Tawk() {
  return (
    <TawkMessengerReact
      propertyId="661d771f1ec1082f04e2a51f"
      widgetId="1hrhhikvm"
    />
  );
}
